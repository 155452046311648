import React, { useEffect, useState } from "react";
import WhyUs from "../components/WhyUs";
import { Test_details_logic } from "../components/Test_details_logic";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Page = () => {
  const [category, setCategory] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(null);
  const { slug } = useParams();
  const location = useLocation();

  useEffect(() => {
    console.log("Current Pathname:", location.pathname);
    setCurrentUrl(location.pathname);
    // Update category based on the pathname
    if (location.pathname.startsWith("/all-test")) {
      setCategory("all-test");
    } else if (location.pathname.startsWith("/packages")) {
      setCategory("packages");
    } else if (location.pathname.startsWith("/parameter")) {
      setCategory("parameter");
    } else if (location.pathname.startsWith("/test-detail")) {
      setCategory("test-detail");
    } else {
      setCategory(""); // Fallback to ensure no incorrect category
    }
  }, [location.pathname]); // Ensure the effect runs whenever pathname changes
  const pageUrl = window.location.href;

  if (!slug || !category) {
    return <div>Loading...</div>; // Fallback until both `slug` and `category` are set
  }

  return (
    <>
      {category && (
        <Helmet>
          <title>{`${slug} Lab in Jalandhar | ${slug} Test Details | Assure Pathlabs`}</title>
          <link rel="canonical" href={pageUrl} />
          <meta
            name="description"
            content={`Explore ${slug} test details at Assure Pathlabs. Get accurate results and affordable prices for ${slug} in Jalandhar.`}
          />
          <meta
            name="keywords"
            content={`blood test, ${slug}, Jalandhar, Assure Pathlabs`}
          />
          <meta
            property="og:title"
            content={`${slug}Lab in Jalandhar | ${slug} Test Details | Assure Pathlabs`}
          />
          <meta
            property="og:description"
            content={`Explore ${slug} test details at Assure Pathlabs. Get accurate results and affordable prices for ${slug} in Jalandhar.`}
          />
          <meta
            property="og:image"
            content="https://www.assurepathlabs.com/images/assure-logo.png"
          />
          <meta property="og:url" content={pageUrl} />
          <meta
            name="twitter:title"
            content={`Blood Test Lab in Jalandhar | ${slug} Test Details | Assure Pathlabs`}
          />
          <meta
            name="twitter:description"
            content={`Explore ${slug} test details at Assure Pathlabs. Get accurate results and affordable prices for ${slug} in Jalandhar.`}
          />
          <meta name="twitter:url" content={pageUrl} />

          <meta name="twitter:card" content="summary" />
        </Helmet>
      )}
      <main className="d-flex flex-wrap float-start col-12">
        <section>
          <div className="container">
            <div className="web-container">
              <div className="col-12">
                <Test_details_logic Slug={slug} />
              </div>
            </div>
          </div>
          <div className="pt-5">
            <WhyUs Title="WHY ASSURE PATHLABS?" />
          </div>
        </section>
      </main>
    </>
  );
};

export default Page;
