import React, { useEffect } from "react";
import Searchhpage from "./../../components/SearchhPage";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const SearchBar = () => {
  const { slug } = useParams();
  const searchTerm = slug
    ?.split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  // Create a search-specific description
  const description = `Search results for ${searchTerm} - Browse our collection of medical tests, health packages, and diagnostic services. Find detailed information about tests, prices, and home collection services.`;

  // Generate relevant keywords incorporating the search term
  const keywords = [
    searchTerm,
    "medical test search",
    "diagnostic test search",
    "lab test search",
    "health tests",
    "medical diagnostics",
    "pathology tests",
    "test packages",
    "home collection",
  ]
    .filter(Boolean)
    .join(", ");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [slug]);

  const pageUrl = window.location.href;
  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>{`Search Results for ${searchTerm} | Medical Laboratory Tests`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={`Medical Test Search Results - ${searchTerm}`}
        />
        
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        {/* Canonical */}
        <link rel="canonical" href={pageUrl} />

        {/* Alternate */}
        <link rel="alternate" hreflang="en" href={pageUrl} />
        <link
          rel="alternate"
          hreflang="es"
          href={pageUrl.replace("/en/", "/es/")}
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`Test Search Results - ${searchTerm}`}
        />
        <meta name="twitter:description" content={description} />

        {/* Additional Meta Tags */}
        <meta name="robots" content="noindex, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Schema.org Markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SearchResultsPage",
            name: `Search Results for ${searchTerm}`,
            description: description,
            mainEntity: {
              "@type": "ItemList",
              itemListElement: {
                "@type": "ListItem",
                name: `Medical Tests and Packages related to ${searchTerm}`,
                description: `Search results showing medical tests and diagnostic packages for ${searchTerm}`,
              },
            },
          })}
        </script>
      </Helmet>
      <div>
        <section className="position-relative">
          <div className="container">
            <div className="web-container">
              <div className="row">
                <div className="title col-12 float-start text-center">
                  <h1 className="text-uppercase">
                    Search Result - {convertSlugToText(slug)}{" "}
                  </h1>
                </div>
                <div className="col-12 float-start all-test">
                  <Searchhpage slug={slug} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SearchBar;

const convertSlugToText = (slug) => {
  // Implement your logic to convert the slug to normal text
  // For simplicity, let's just replace hyphens with spaces
  return slug.replace(/-/g, " ");
};
