import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Helmet } from "react-helmet";
import PharmacyIcon3 from "../components/svg-components/pharmacyIcon3";
import PharmacyIcon2 from "../components/svg-components/PharmacyIcon2";
import PharmacyIcon1 from "../components/svg-components/PharmacyIcon1";
import AttractiveDeal from "../components/svg-components/AttractiveDeal";
import MedicalSupplies from "../components/svg-components/MedicalSupplies";
import Refrigreation from "../components/svg-components/Refrigreation";
import SuperiorQuality from "../components/svg-components/SuperiorQuality";
import Reward from "../components/svg-components/Reward";
import EasyReturn from "../components/svg-components/EasyReturn";
import PharmacyConsultancy from "../components/svg-components/PharmacyConsultancy";
import Open from "../components/svg-components/Open";
import PlayStoreColor from "../components/svg-components/PlayStoreColor";
import Aboutbanner from "../images/PharmacyBannerDesktop.webp";
import AboutbannerM from "../images/pharmacyBannerMobile.webp";
import brand01 from "../images/barnd-partner-01.jpg";
import brand02 from "../images/barnd-partner-02.png";
import brand03 from "../images/barnd-partner-03.png";
import brand04 from "../images/barnd-partner-04.png";
import brand05 from "../images/barnd-partner-05.png";
import brand06 from "../images/barnd-partner-06.png";
import brand07 from "../images/barnd-partner-07.png";
import pharmacyQR from "../images/pharmacy QR.webp";
import Aos from "aos";
import "aos/dist/aos.css"; // Import AOS CSS
import "./index.css"; // Import AOS CSS
import { Link } from "react-router-dom";
import pharmacyData from "../Data/pharmacy.json";
import Slider from "react-slick";
import { MdClose } from "react-icons/md";
import { Faq } from "../components/Faq";
import Nabh from "../components/svg-components/Nabh";

const Page = () => {
  useEffect(() => {
    Aos.init({
      delay: 0,
      anchorPlacement: "top-bottom",
    });
  }, []);
  const [isHovered, setIsHovered] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => setIsVisible(false), 500); // Delays the removal to allow animation to complete
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    pauseOnHover: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          autoplaySpeed: 6000,
          infinite: true,
          loop: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Best Pharmacy in Jalandhar | Buy Medicines | Assure Pathlabs
        </title>
        <meta
          name="description"
          content="Assure Pathlabs offers a convenient pharmacy in Jalandhar. Browse our wide selection of medicines, healthcare products, and wellness essentials. Order for home delivery or pickup at our center"
        />
        <link rel="canonical" to="https://www.assurepathlabs.com/pharmacy" />
      </Helmet>
      <main className="d-flex flex-wrap float-start col-12 _parmacy">
        <section className="position-relative __about__ mt-md-4 ">
          <div className="container">
            <div className="row">
              <div
                className="col-12 abt_banner"
                data-aos="fade-in"
                data-aos-duration={600}
                data-aos-once="true"
                data-aos-anchor-placement="top-bottom"
                data-aos-easing="ease-in"
              >
                <img
                  src={Aboutbanner}
                  alt="Jalandhar’s Trusted Medical Store"
                  width={1220}
                  height={730}
                  className="desktop-show d-none d-sm-block h-auto"
                />
                <img
                  src={AboutbannerM}
                  className="mobile-show d-block d-sm-none"
                  alt="Jalandhar’s Trusted Medical Store"
                  width={640}
                  height={720}
                /> 
              </div>

              <div role="presentation" className="bread_crums">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/">Home</Link>
                  <Link to="#">Pharmacy</Link>
                </Breadcrumbs>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative  py-5">
          <div className="container">
            <div className="web-container my-2">
              <div className="row">
                <div
                  className="title text-center"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h2>ABOUT ASSURE MEDICOS</h2>
                </div>

                <div className="m-auto col-12 col-md-10 d-flex justify-content-center flex-direction-row text-center pt-md-3 pt-1">
                  <div
                    className="d-flex justify-content-center text-left flex-column col-12 col-md-10"
                    data-aos="fade-up"
                    data-aos-duration={600}
                    data-aos-once="true"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="ease-in"
                  >
                    <p className="about-assure-text col-12">
                      Assure Medicos is subsidiary of Assure Pathlabs,
                      Jalandhar’s most trusted pathology lab. Whether you need
                      prescription medications, over-the-counter remedies,
                      wellness products, or personalized health advice, Assure
                      Medicos is your one-stop destination.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="position-relative ">
          <div className="container">
            <div className="web-container my-md-4 my-2">
              <div className="row">
                <div
                  className="title text-center"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h2>BRAND PARTNERS</h2>
                </div>
                <div
                  className="m-auto col-12 col-md-10 d-flex justify-content-center mt-md-4 pt-md-2 mt-2 py-1 arrows brandpartner "
                  data-aos="fade-up"
                  data-aos-duration={500}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <Slider {...settings}>
                    <div>
                      <img
                        className=" barnd_partner_card mx-auto"
                        src={brand01}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand02}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand03}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand04}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand05}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand06}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                    <div>
                      <img
                        className="mx-auto barnd_partner_card"
                        src={brand07}
                        alt="Early Detection is the Key of Cure"
                        width={120}
                        height={120}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="position-relative py-5">
          <div className="container">
            <div className="web-container my-2">
              <div className="row">
                <div
                  className="title text-center"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h2>WHY CHOOSE ASSURE MEDICOS?</h2>
                </div>

                <div
                  className="m-auto pt-4 col-12 col-xxl-10 d-flex justify-content-center flex-direction-row text-center"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <div className="feature-cnt">
                    <div
                      className="feature-card"
                      onMouseEnter={() => setIsHovered(1)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      <div
                        className={`booble  ${
                          isHovered == 1 ? "isHovered" : ""
                        }`}
                      ></div>
                      <div className="feature-card-header">
                        <div className="feature-card-icon feature-card-icon-fast icon">
                          <PharmacyIcon1 />
                        </div>
                        <h3 className="feature-card-title">RELIABLE</h3>
                      </div>
                      <p className="feature-card-description">
                        Assure Medicos products and medicines are sourced
                        exclusively from licensed and verified chemists,
                        ensuring that every item meets the highest standards of
                        quality and authenticity.
                      </p>
                    </div>
                    <div
                      className="feature-card"
                      onMouseEnter={() => setIsHovered(2)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      <div
                        className={`booble colour-1  ${
                          isHovered == 2 ? "isHovered" : ""
                        }`}
                      ></div>
                      <div className="feature-card-header">
                        <div className="feature-card-icon feature-card-icon-svg icon">
                          <PharmacyIcon2 />
                        </div>
                        <h3 className="feature-card-title">AFFORDABLE</h3>
                      </div>
                      <p className="feature-card-description">
                        At Assure Medicos, we prioritize your well-being by
                        offering affordable medication along with generous
                        discounts on lab tests, making quality healthcare
                        accessible and affordable for all.
                      </p>
                    </div>
                    <div
                      className="feature-card"
                      onMouseEnter={() => setIsHovered(3)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      <div
                        className={`booble colour-2  ${
                          isHovered == 3 ? "isHovered" : ""
                        }`}
                      ></div>
                      <div className="feature-card-header">
                        <div className="feature-card-icon feature-card-icon-delightful icon">
                          <PharmacyIcon3 />
                        </div>
                        <h3 className="feature-card-title">SECURE</h3>
                      </div>
                      <p className="feature-card-description">
                        Assure Pathlabs and Assure Medicos uphold strict
                        confidentiality measures, guaranteeing the security and
                        privacy of all prescription and consumer data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="position-relative  bg-gray py-5">
          <div className="container ">
            <div className="web-container my-2">
              <div className="row">
                <div
                  className="title text-center"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                  data-aos-anchor-placement="top-bottom"
                  data-aos-easing="ease-in"
                >
                  <h2>ALL YOUR MEDICINE NEEDS UNDER ONE ROOF </h2>
                </div>
                <div
                  className="our-services col-12 col-md-10 mx-auto
                "
                >
                  {/* <p className="subtitle">
                  All Your Medicine Needs Under One Roof
                  </p> */}
                  <div
                    className="services-container"
                    data-aos="fade-up"
                    data-aos-duration={600}
                    data-aos-once="true"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-easing="ease-in"
                  >
                    <div className="service">
                      <div className="icon strategy">
                        <Nabh />
                      </div>
                      <h3 className="sevieces_heading">NABH Certified Labs</h3>
                      <p>
                        Avail special discounts and offers on a wide range of
                        medications to save on your healthcare expenses.
                      </p>
                    </div>
                    <div className="service">
                      <div className="icon strategy">
                        <AttractiveDeal />
                      </div>
                      <h3 className="sevieces_heading">Attractive deals </h3>
                      <p>
                        Avail special discounts and offers on a wide range of
                        medications to save on your healthcare expenses.
                      </p>
                    </div>
                    <div className="service">
                      <div className="icon branding">
                        <MedicalSupplies />
                      </div>
                      <h3 className="sevieces_heading">Affordable Pricing</h3>
                      <p>
                        An extensive selection of healthcare products, including
                        medications, supplements, wellness items, and more, all
                        under one roof.
                      </p>
                    </div>
                    <div className="service">
                      <div className="icon development">
                        <Refrigreation />
                      </div>
                      <h3 className="sevieces_heading">
                        100% Genuine Medicine
                      </h3>
                      <p>
                        Our state-of-the-art cold storage facilities ensure the
                        safe preservation of vaccines and temperature-sensitive
                        medications, maintaining their efficacy.
                      </p>
                    </div>
                    <div className="service">
                      <div className="icon web-design">
                        <SuperiorQuality />
                      </div>
                      <h3 className="sevieces_heading">
                        Flexible Payment Options
                      </h3>
                      <p>
                        All our products are genuine, sourced from reputable
                        manufacturers, and undergo strict quality checks to
                        ensure efficacy and safety.
                      </p>
                    </div>
                    <div className="service">
                      <div className="icon social-media">
                        <Reward />
                      </div>
                      <h3 className="sevieces_heading">
                        Fastest home delivery
                      </h3>
                    </div>
                    <div className="service">
                      <div className="icon ecommerce">
                        <EasyReturn />
                      </div>
                      <h3 className="sevieces_heading">Place order anytime</h3>
                    </div>
                    <div className="service">
                      <div className="icon ecommerce">
                        <PharmacyConsultancy />
                      </div>
                      <h3 className="sevieces_heading">
                        24/7 pharmacist consultation
                      </h3>
                      <p>
                        Access expert guidance and advice from our qualified
                        pharmacists round the clock, whenever you have questions
                        or need medication-related assistance.
                      </p>
                    </div>
                    {/* <div className="service">
                      <div className="icon ecommerce">
                        <Open />
                      </div>
                      <h3 className="sevieces_heading">24X7 open</h3>
                      <p>
                        We're here for you 24 hours a day, 7 days a week,
                        ensuring you have access to healthcare products and
                        services whenever you need them, day or night.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="faq">
          <div className="container position-relative z-index-2">
            <div className="web-container">
              <div
                className="row"
                data-aos="fade-up"
                data-aos-duration={500}
                data-aos-once="true"
                data-aos-easing="ease-in"
              >
                <Faq className="minusbottom " slug="medicos" />
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div
            className={`uniqueQrCodeContainer ${
              isClosing ? "slide-out" : "slide-right"
            }`}
          >
            <div className="uniqueQrCodeCloseIcon">
              <span onClick={handleClose}>
                <MdClose />
              </span>
            </div>
            <div className="uniqueQrCodeTop">
              <p>
                Scan to order medicines <br />
                <span>Get 10% Off</span>
              </p>
            </div>
            <div className="uniqueQrCodeBottom">
              <img
                alt="QR CODE"
                loading="lazy"
                width="98"
                height="98"
                src={pharmacyQR}
              />
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default Page;
