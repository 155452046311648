import React from "react";
import Sucess from "./svg-components/Sucess";
import { Line } from "./svg-components/Line";
import { Dots } from "./svg-components/Dots";
import { useData } from "../context/context";
import { Link } from "react-router-dom";

const SuccessMessage = ({ data }) => {
  // Access the collected data and display it in the success message
  const { cartState, cartDispatch } = useData();
  console.log(cartState.userData);
  return (
    <>
      <section className="position-relative py-5">
        <div className="container text-center">
          <div className="web-container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-12">
                {/* Success Icon */}
                <div className="success-icon">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      margin: "0 auto 20px",
                      backgroundColor: "#4CAF50",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      style={{
                        fontSize: "40px",
                        color: "#fff",
                      }}
                      className="fa fa-check"
                    ></i>
                  </div>
                </div>
                {/* Title */}
                {cartState.userData.name && (
                  <div className="title text-center">
                    <h1 style={{ fontWeight: "bold" }}>
                      Congratulations, {cartState.userData.name}!
                    </h1>
                  </div>
                )}
                {/* Message */}
                <div className="sucees_mesg-message mx-auto mt-3">
                  {cartState.userData.packageNames && (
                    <h3 className="mb-4">
                      Your
                      {cartState.userData.isHomecollection === 1
                        ? " home collection"
                        : " Walk in"}{" "}
                      for the {cartState.userData.packageNames} has been
                      successfully booked!
                    </h3>
                  )}
                  {/* /* Details */}
                  <div>
                    {cartState.userData.dateandtime && (
                      <>
                        <p className="mb-0">
                          <strong>Home Collection Date:</strong>{" "}
                          {new Date(
                            cartState.userData.dateandtime
                          ).toLocaleDateString()}
                        </p>
                        <p className="mb-0">
                          <strong>Home Collection Time:</strong>{" "}
                          {new Date(
                            cartState.userData.dateandtime
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                      </>
                    )}
                  </div>
                  {/* Footer Message */}
                  <p className="mt-3">
                    Thank you for reaching out to us. Our team will confirm the
                    details and connect with you shortly. If your matter
                    requires urgent attention, please call us at{" "}
                    <Link to="tel:0181-4667555">0181-4667555.</Link>
                  </p>
                  <p>
                    Thank you again for choosing Assure Pathlabs for your
                    wellness journey!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dots className="hsection position-absolute svgwidth opacity-10" />
        <Line className="svgwidthline position-absolute opacity-10 top-20 end-0" />
      </section>
    </>
  );
};

export default SuccessMessage;
