"use client";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Page = () => {
  const location = useLocation();

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");
  const dateTime = queryParams.get("dateTime");
  const packageName = queryParams.get("packageName");
  const phone = queryParams.get("phone");
  const email = queryParams.get("email");
  const packageAmount = queryParams.get("packageAmount");

  let date = null;
  let timeRange = null;

  // Calculate date and time range if dateTime exists
  if (dateTime) {
    date = new Date(dateTime).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const startTime = new Date(dateTime);
    const endTime = new Date(startTime.getTime() + 15 * 60 * 1000); // Add 15 minutes

    const formattedStartTime = startTime.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });
    const formattedEndTime = endTime.toLocaleTimeString("en-US", {
      timeStyle: "short",
    });

    timeRange = `${formattedStartTime} - ${formattedEndTime}`;
  }

  return (
    <>
      <section className="position-relative py-5">
        <div className="container text-center">
          <div className="web-container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-12">
                {/* Success Icon */}
                <div className="success-icon">
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      margin: "0 auto 20px",
                      backgroundColor: "#4CAF50",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      style={{
                        fontSize: "40px",
                        color: "#fff",
                      }}
                      className="fa fa-check"
                    ></i>
                  </div>
                </div>
                {/* Title */}
                {name && (
                  <div className="title text-center">
                    <h1 style={{ fontWeight: "bold" }}>
                      Congratulations, {name}!
                    </h1>
                  </div>
                )}
                {/* Message */}
                <div className="sucees_mesg-message mx-auto mt-3">
                  {packageName && (
                    <h3 className="mb-4">
                      Your home collection for the {packageName} has been
                      successfully booked!
                    </h3>
                  )}
                  {/* Details */}
                  <div>
                    {date && (
                      <p className="mb-0">
                        <strong>Home Collection Date:</strong> {date}
                      </p>
                    )}
                    {timeRange && (
                      <p>
                        <strong>Home Collection Time:</strong> {timeRange}
                      </p>
                    )}
                  </div>
                  {/* Footer Message */}
                  <p>
                    Thank you for reaching out to us. Our team will confirm the
                    details and connect with you shortly. If your matter
                    requires urgent attention, please call us at{" "}
                    <Link to="tel:0181-4667555">0181-4667555.</Link>
                  </p>
                  <p>
                    Thank you again for choosing Assure Pathlabs for your
                    wellness journey!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page;
